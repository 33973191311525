import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MainLayout from "layouts/MainLayout";
import PrivateRoute from "./PrivateRoute";
import AdminRoute from "./AdminRoute";

const DashboardDefault = Loadable(lazy(() => import("pages/dashboard")));
const Agents = Loadable(lazy(() => import("pages/agents")));
const AgentsList = Loadable(lazy(() => import("pages/agents/AgentsList")));
const EditPolicy = Loadable(lazy(() => import("pages/agents/edit-policy")));
const Documentation = Loadable(lazy(() => import("pages/documentation")));
const AccountSettings = Loadable(lazy(() => import("pages/account-settings")));
const AccountTabPersonal = Loadable(
  lazy(() => import("pages/account-settings/tabs/AccountTabPersonal"))
);
const AccountTabEmail = Loadable(lazy(() => import("pages/account-settings/tabs/AccountTabEmail")));
const AccountTabPassword = Loadable(
  lazy(() => import("pages/account-settings/tabs/AccountTabPassword"))
);
const AccountTabSettings = Loadable(
  lazy(() => import("pages/account-settings/tabs/AccountTabSettings"))
);
const Admin = Loadable(lazy(() => import("pages/admin")));
const AdminTabUsers = Loadable(lazy(() => import("pages/admin/tabs/users")));
const AdminTabUserAgents = Loadable(lazy(() => import("pages/admin/tabs/user-agents")));
const AdminTabSubscribers = Loadable(lazy(() => import("pages/admin/tabs/subscribers")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    <PrivateRoute>
      <MainLayout />
    </PrivateRoute>
  ),
  children: [
    {
      path: "/",
      element: <DashboardDefault />,
    },
    {
      path: "dashboard",
      children: [
        {
          path: "default",
          element: <DashboardDefault />,
        },
      ],
    },
    {
      path: "agents",
      element: <Agents />,
      children: [
        {
          index: true, // This represents the /agents route
          element: <AgentsList />,
        },
        {
          path: "edit-policy/:id",
          element: <EditPolicy />,
        },
      ],
    },
    {
      path: "documentation",
      element: <Documentation />,
    },
    {
      path: "account",
      children: [
        {
          path: "settings",
          element: <AccountSettings />,
          children: [
            {
              path: "personal",
              element: <AccountTabPersonal />,
            },
            {
              path: "email",
              element: <AccountTabEmail />,
            },
            {
              path: "password",
              element: <AccountTabPassword />,
            },
            {
              path: "settings",
              element: <AccountTabSettings />,
            },
          ],
        },
      ],
    },
    {
      path: "admin",
      element: (
        <AdminRoute>
          <Admin />
        </AdminRoute>
      ),
      children: [
        {
          path: "accounts",
          element: <AdminTabUsers />,
        },
        {
          path: "user-agents",
          element: <AdminTabUserAgents />,
        },
        {
          path: "subscribers",
          element: <AdminTabSubscribers />,
        },
      ],
    },
  ],
};

export default MainRoutes;
