// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { Environment, getEnvironment } from "utils/environments";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const portalFirebaseConfig = {
  apiKey: "AIzaSyA1AhBUNAmUqoLrAhXQmOtcwZlK94YkmRY",
  authDomain: "aigency-portal.firebaseapp.com",
  projectId: "aigency-portal",
  storageBucket: "aigency-portal.appspot.com",
  messagingSenderId: "354199154851",
  appId: "1:354199154851:web:4f602b1f98ba2c5624cf16",
  measurementId: "G-9M32SQTXD6",
};

// Initialize Firebase
const app = initializeApp(portalFirebaseConfig);
// const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions();
export const storage = getStorage(app);

export default app;

const apiFirebaseConfig = {
  apiKey: "AIzaSyDhof-3yQqms7q93F-3cpaqxnAlWchR62k",
  authDomain: "aigency-api.firebaseapp.com",
  projectId: "aigency-api",
  storageBucket: "aigency-api.appspot.com",
  messagingSenderId: "520549900892",
  appId: "1:520549900892:web:b13a8d85e1fc902f4bfc99",
  measurementId: "G-X01T4TFZSN",
};

const apiApp = initializeApp(apiFirebaseConfig, "AigencyAPI");

export const apiDb = getFirestore(apiApp);

const landingFirebaseConfig = {
  apiKey: "AIzaSyAJSI6YD1PvYMh2-UomuRZ6Tx-9ZdCgQpI",
  authDomain: "aigency-homepage.firebaseapp.com",
  projectId: "aigency-homepage",
  storageBucket: "aigency-homepage.firebasestorage.app",
  messagingSenderId: "245747373437",
  appId: "1:245747373437:web:3f00168cd636f3bf51e814",
  measurementId: "G-39F0EV4XXV",
};

const landingApp = initializeApp(landingFirebaseConfig, "AigencyLanding");

export const landingDb =
  getEnvironment() === Environment.STAGING
    ? getFirestore(landingApp, Environment.STAGING)
    : getFirestore(landingApp);

if (getEnvironment() === Environment.LOCAL) {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(db, "localhost", 8082);
  connectFirestoreEmulator(apiDb, "localhost", 8084);
  connectFirestoreEmulator(landingDb, "localhost", 8086);
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectStorageEmulator(storage, "localhost", 9199);
}
